






































































import { computed, defineComponent } from '@nuxtjs/composition-api';
import { useUser } from '~/modules/customer/composables/useUser';
import WishlistGuest from '~/components/General/WishlistGuest.vue';

export default defineComponent({
  name: 'RecentlyViewedProductsCarousel',
  components: {
    WishlistGuest,
    'Carousel': typeof window !== 'undefined' ? () => import('vue-owl-carousel') : null,
  },
  props: {
    productsData: {
      type: [Object, Array],
      required: false,
      default: () => [],
    }
  },
  setup(props) {
    const { isAuthenticated } = useUser();
    const recommendedResponsive = {
      items: 2,
      margin: 18,
      center: true
    }
    const products = computed(() => props.productsData['items']);
    const guestProducts = computed(() => props.productsData);

    const productImage = (prod) => prod?.image?.url
    const splitDateAndText = (inputString) => {

      const match = inputString.match(/^\d{4}\b/);
      if (match) {
        const year = match[0];
        const text = inputString.slice(year.length).trim();
        return [year, text];
      } else {
        return [null, inputString];
      }
    }

    return {
      productImage,
      guestProducts,
      products,
      isAuthenticated,
      recommendedResponsive,
      splitDateAndText
    };
  },
});
