





import {
  defineComponent, ref, onMounted, useRoute, computed, watch,
} from '@nuxtjs/composition-api';
import useRelatedProducts from '~/modules/catalog/product/composables/useRelatedProducts';
import RecentlyViewedProductsCarousel from '~/modules/catalog/product/components/RecentlyViewedProductsCarousel.vue';
import { useUser } from '~/composables';

export default defineComponent({
  name: 'RecentlyViewedProducts',
  components: {
    RecentlyViewedProductsCarousel,
  },
  setup() {
    const route = useRoute();
    const { getRecentViewedProducts, isAuthenticated } = useUser();
    const productsData = ref([]);

    onMounted(async () => {
      if (isAuthenticated.value) {
        setTimeout(async ()=>{
          await getRecentlyViewedProducts()
        }, 3000)
      }
      if (!isAuthenticated.value) {
        getGuestRecentlyViewedProducts()
      }
    });

    const getGuestRecentlyViewedProducts = () => {
      let guestRecentViewList = []
      if (localStorage.getItem("guestRecentViewedArray")) {
        guestRecentViewList = JSON.parse(localStorage.getItem("guestRecentViewedArray"));
      }
      productsData.value = guestRecentViewList
    }

    const getRecentlyViewedProducts = async () => {
      try {
        let viewedProducts = await getRecentViewedProducts()
        if(viewedProducts['recentViewedProducts']) {
          productsData.value = viewedProducts['recentViewedProducts']
          if (productsData.value.length > 10) {
            productsData.value.shift()
          }
        }
      } catch (e) {
        console.log('error in recently viewed products', e)
      }
    }

    return {
      productsData
    };
  },
});
